const types = {
  bug: {
    normal: 0,
    fighting: -1,
    flying: -1,
    poison: -1,
    ground: 0,
    rock: 0,
    bug: 0,
    ghost: -1,
    steel: -1,
    fire: -1,
    water: 0,
    grass: 1,
    electric: 0,
    psychic: 1,
    ice: 0,
    dragon: 0,
    fairy: -1,
    dark: 1,
    null: 0
  },
  dark: {
    normal: 0,
    fighting: -1,
    flying: 0,
    poison: 0,
    ground: 0,
    rock: 0,
    bug: 0,
    ghost: 1,
    steel: 0,
    fire: 0,
    water: 0,
    grass: 0,
    electric: 0,
    psychic: 1,
    ice: 0,
    dragon: 0,
    fairy: -1,
    dark: -1,
    null: 0
  },
  dragon: {
    normal: 0,
    fighting: 0,
    flying: 0,
    poison: 0,
    ground: 0,
    rock: 0,
    bug: 0,
    ghost: 0,
    steel: -1,
    fire: 0,
    water: 0,
    grass: 0,
    electric: 0,
    psychic: 0,
    ice: 0,
    dragon: 1,
    fairy: -100,
    dark: 0,
    null: 0
  },
  electric: {
    normal: 0,
    fighting: 0,
    flying: 1,
    poison: 0,
    ground: -100,
    rock: 0,
    bug: 0,
    ghost: 0,
    steel: 0,
    fire: 0,
    water: 1,
    grass: -1,
    electric: -1,
    psychic: 0,
    ice: 0,
    dragon: -1,
    fairy: 0,
    dark: 0,
    null: 0
  },
  fairy: {
    normal: 0,
    fighting: 1,
    flying: 0,
    poison: -1,
    ground: 0,
    rock: 0,
    bug: 0,
    ghost: 0,
    steel: -1,
    fire: -1,
    water: 0,
    grass: 0,
    electric: 0,
    psychic: 0,
    ice: 0,
    dragon: 1,
    fairy: 0,
    dark: 1,
    null: 0
  },
  fighting: {
    normal: 1,
    fighting: 0,
    flying: -1,
    poison: -1,
    ground: 0,
    rock: 1,
    bug: -1,
    ghost: -100,
    steel: 1,
    fire: 0,
    water: 0,
    grass: 0,
    electric: 0,
    psychic: -1,
    ice: 1,
    dragon: 0,
    fairy: -1,
    dark: 1,
    null: 0
  },
  fire: {
    normal: 0,
    fighting: 0,
    flying: 0,
    poison: 0,
    ground: 0,
    rock: -1,
    bug: 1,
    ghost: 0,
    steel: 1,
    fire: -1,
    water: -1,
    grass: 1,
    electric: 0,
    psychic: 0,
    ice: 1,
    dragon: -1,
    fairy: 0,
    dark: 0,
    null: 0
  },
  flying: {
    normal: 0,
    fighting: 1,
    flying: 0,
    poison: 0,
    ground: 0,
    rock: -1,
    bug: 1,
    ghost: 0,
    steel: -1,
    fire: 0,
    water: 0,
    grass: 1,
    electric: -1,
    psychic: 0,
    ice: 0,
    dragon: 0,
    fairy: 0,
    dark: 0,
    null: 0
  },
  ghost: {
    normal: -100,
    fighting: 0,
    flying: 0,
    poison: 0,
    ground: 0,
    rock: 0,
    bug: 0,
    ghost: 1,
    steel: 0,
    fire: 0,
    water: 0,
    grass: 0,
    electric: 0,
    psychic: 1,
    ice: 0,
    dragon: 0,
    fairy: 0,
    dark: -1,
    null: 0
  },
  grass: {
    normal: 0,
    fighting: 0,
    flying: -1,
    poison: -1,
    ground: 1,
    rock: 1,
    bug: -1,
    ghost: 0,
    steel: -1,
    fire: -1,
    water: 1,
    grass: -1,
    electric: 0,
    psychic: 0,
    ice: 0,
    dragon: -1,
    fairy: 0,
    dark: 0,
    null: 0
  },
  ground: {
    normal: 0,
    fighting: 0,
    flying: -100,
    poison: 1,
    ground: 0,
    rock: 1,
    bug: -1,
    ghost: 0,
    steel: 1,
    fire: 1,
    water: 0,
    grass: -1,
    electric: 1,
    psychic: 0,
    ice: 0,
    dragon: 0,
    fairy: 0,
    dark: 0,
    null: 0
  },
  ice: {
    normal: 0,
    fighting: 0,
    flying: 1,
    poison: 0,
    ground: 1,
    rock: 0,
    bug: 0,
    ghost: 0,
    steel: -1,
    fire: -1,
    water: -1,
    grass: 1,
    electric: 0,
    psychic: 0,
    ice: -1,
    dragon: 1,
    fairy: 0,
    dark: 0,
    null: 0
  },
  normal: {
    normal: 0,
    fighting: 0,
    flying: 0,
    poison: 0,
    ground: 0,
    rock: -1,
    bug: 0,
    ghost: -100,
    steel: -1,
    fire: 0,
    water: 0,
    grass: 0,
    electric: 0,
    psychic: 0,
    ice: 0,
    dragon: 0,
    fairy: 0,
    dark: 0,
    null: 0
  },
  poison: {
    normal: 0,
    fighting: 0,
    flying: 0,
    poison: -1,
    ground: -1,
    rock: -1,
    bug: 0,
    ghost: -1,
    steel: -100,
    fire: 0,
    water: 0,
    grass: 1,
    electric: 0,
    psychic: 0,
    ice: 0,
    dragon: 0,
    fairy: 1,
    dark: 0,
    null: 0
  },
  psychic: {
    normal: 0,
    fighting: 1,
    flying: 0,
    poison: 1,
    ground: 0,
    rock: 0,
    bug: 0,
    ghost: 0,
    steel: -1,
    fire: 0,
    water: 0,
    grass: 0,
    electric: 0,
    psychic: -1,
    ice: 0,
    dragon: 0,
    fairy: 0,
    dark: -100,
    null: 0
  },
  rock: {
    normal: 0,
    fighting: -1,
    flying: 1,
    poison: 0,
    ground: -1,
    rock: 0,
    bug: 1,
    ghost: 0,
    steel: -1,
    fire: 1,
    water: 0,
    grass: 0,
    electric: 0,
    psychic: 0,
    ice: 1,
    dragon: 0,
    fairy: 0,
    dark: 0,
    null: 0
  },
  steel: {
    normal: 0,
    fighting: 0,
    flying: 0,
    poison: 0,
    ground: 0,
    rock: 1,
    bug: 0,
    ghost: 0,
    steel: -1,
    fire: -1,
    water: -1,
    grass: 0,
    electric: -1,
    psychic: 0,
    ice: 1,
    dragon: 0,
    fairy: 1,
    dark: 0,
    null: 0
  },
  water: {
    normal: 0,
    fighting: 0,
    flying: 0,
    poison: 0,
    ground: 1,
    rock: 1,
    bug: 0,
    ghost: 0,
    steel: 0,
    fire: 1,
    water: -1,
    grass: -1,
    electric: 0,
    psychic: 0,
    ice: 0,
    dragon: -1,
    fairy: 0,
    dark: 0,
    null: 0
  }
};
export default types;
