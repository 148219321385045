const translations = {
  es: {
    bug: "Bicho",
    dark: "Siniestro",
    dragon: "Dragón",
    electric: "Eléctrico",
    fairy: "Hada",
    fighting: "Lucha",
    fire: "Fuego",
    flying: "Volador",
    ghost: "Fantasma",
    grass: "Planta",
    ground: "Tierra",
    ice: "Hielo",
    normal: "Normal",
    poison: "Veneno",
    psychic: "Psíquico",
    rock: "Roca",
    steel: "Acero",
    water: "Agua",
    select: "Selecciona el/los tipo(s) del pokémon objetivo",
    pokemon_select:
      "O selecciona el pokémon enemigo (puedes escribir para encontrarlo más rápido!)",
    color_legend: "Leyenda de colores",
    immune: "Inmune",
    donate: "Donar",
    switch: "Switch to English",
    select_placeholder: "Seleccionar..."
  },
  en: {
    bug: "Bug",
    dark: "Dark",
    dragon: "Dragon",
    electric: "Electric",
    fairy: "Fairy",
    fighting: "Fighting",
    fire: "Fire",
    flying: "Flying",
    ghost: "Ghost",
    grass: "Grass",
    ground: "Ground",
    ice: "Ice",
    normal: "Normal",
    poison: "Poison",
    psychic: "Psychic",
    rock: "Rock",
    steel: "Steel",
    water: "Water",
    select: "Select the enemy type(s)",
    pokemon_select:
      "Or select the enemy pokémon directly (you can type to find it faster!)",
    color_legend: "Color Legend",
    immune: "Immune",
    donate: "Donate",
    switch: "Cambiar a español",
    select_placeholder: "Select..."
  }
};

export default translations;